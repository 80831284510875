/**
 * Subscription plans
 */

export const PLAN_SE_2021_STARTER_ANNUAL = '2021-se-general-starter-annual'
export const PLAN_SE_2021_LIGHT_ANNUAL = '2021-se-light-annual'
export const PLAN_SE_2021_STANDARD_ANNUAL = '2021-se-standard-annual'
export const PLAN_SE_2021_PREMIUM_ANNUAL = '2021-se-premium-annual'
export const PLAN_SE_2021_PARTNER_BASIC_ANNUAL = '2021-se-partner-basic-annual'
export const PLAN_SE_2021_PARTNER_PREMIUM_ANNUAL = '2021-se-partner-premium-annual'
export const PLAN_SE_2021_ENTERPRISE_ANNUAL = '2021-se-enterprise-annual'

export const PLAN_SE_2021_STARTER_MONTHLY = '2021-se-general-starter-monthly'
export const PLAN_SE_2021_LIGHT_MONTHLY = '2021-se-light-monthly'
export const PLAN_SE_2021_STANDARD_MONTHLY = '2021-se-standard-monthly'
export const PLAN_SE_2021_PREMIUM_MONTHLY = '2021-se-premium-monthly'
export const PLAN_SE_2021_PARTNER_BASIC_MONTHLY = '2021-se-partner-basic-monthly'
export const PLAN_SE_2021_PARTNER_PREMIUM_MONTHLY = '2021-se-partner-premium-monthly'
export const PLAN_SE_2021_ENTERPRISE_MONTHLY = '2021-se-enterprise-monthly'

export const PLAN_2021_STARTER_ANNUAL = 'general-starter-annual'
export const PLAN_2021_LIGHT_ANNUAL = '2021-light-annual'
export const PLAN_2021_STANDARD_ANNUAL = '2021-standard-annual'
export const PLAN_2021_PREMIUM_ANNUAL = '2021-premium-annual'
export const PLAN_2021_PARTNER_BASIC_ANNUAL = '2021-partner-basic-fixed-annual'

export const PLAN_2021_STARTER_MONTHLY = 'general-starter-monthly'
export const PLAN_2021_LIGHT_MONTHLY = '2021-light-monthly'
export const PLAN_2021_STANDARD_MONTHLY = '2021-standard-monthly'
export const PLAN_2021_PREMIUM_MONTHLY = '2021-premium-monthly'
export const PLAN_2021_PARTNER_BASIC_MONTHLY = '2021-partner-basic-monthly'

export const PLAN_2020_BASIC_ANNUAL = '2020-basic-annual'
export const PLAN_2020_PREMIUM_ANNUAL = '2020-premium-annual'
export const PLAN_2020_PREMIUM_PLUS_ANNUAL = '2020-premium-plus-annual'
export const PLAN_2020_PARTNER_BASIC_ANNUAL = '2020-partner-basic-annual'
export const PLAN_2020_PARTNER_PREMIUM_ANNUAL = '2020-partner-premium-annual'
export const PLAN_2020_ENTERPRISE_ANNUAL = '2020-enterprise-annual'

export const PLAN_2020_BASIC_MONTHLY = '2020-basic-monthly'
export const PLAN_2020_PREMIUM_MONTHLY = '2020-premium-monthly'
export const PLAN_2020_PREMIUM_PLUS_MONTHLY = '2020-premium-plus-monthly'
export const PLAN_2020_PARTNER_BASIC_MONTHLY = '2020-partner-basic-monthly'
export const PLAN_2020_PARTNER_PREMIUM_MONTHLY = '2020-partner-premium-monthly'
export const PLAN_2020_ENTERPRISE_MONTHLY = '2020-enterprise-monthly'

export const PLAN_GENERAL_LIGHT_ANNUAL = 'general-light-annual'
export const PLAN_GENERAL_LIGHT_MONTHLY = 'general-light-monthly'
export const PLAN_GENERAL_BASIC_ANNUAL = 'general-basic-annual'
export const PLAN_GENERAL_BASIC_MONTHLY = 'general-basic-monthly'
export const PLAN_GENERAL_PLUS_ANNUAL = 'general-plus-annual'
export const PLAN_GENERAL_PLUS_MONTHLY = 'general-plus-monthly'
export const PLAN_PARTNER_BASIC_ANNUAL = 'partner-basic-annual'
export const PLAN_PARTNER_BASIC_MONTHLY = 'partner-basic-monthly'
export const PLAN_PARTNER_PLUS_ANNUAL = 'partner-plus-annual'
export const PLAN_PARTNER_PLUS_MONTHLY = 'partner-plus-monthly'

export const PLAN_FINAGO_LIGHT_MONTHLY = 'finago-light-monthly'
export const PLAN_FINAGO_BASIC_MONTHLY = 'finago-basic-monthly'
export const PLAN_FINAGO_PLUS_MONTHLY = 'finago-plus-monthly'

export const PLAN_LEGACY_COMPANY = 'sopimustili-yrityksille'
export const PLAN_LEGACY_COMPANY_S = 'sopimustili-yrityksille-s'
export const PLAN_LEGACY_COMPANY_M = 'sopimustili-yrityksille-m'
export const PLAN_LEGACY_COMPANY_L = 'sopimustili-yrityksille-l'
export const PLAN_LEGACY_COMPANY_XL = 'sopimustili-yrityksille-xl'

export const ACTIVE_FI_PLANS = {
  [PLAN_2021_STARTER_ANNUAL]: 'Starter (annual)',
  [PLAN_2021_LIGHT_ANNUAL]: 'Light (annual)',
  [PLAN_2021_STANDARD_ANNUAL]: 'Standard (annual)',
  [PLAN_2021_PREMIUM_ANNUAL]: 'Premium (annual)',
  [PLAN_2021_PARTNER_BASIC_ANNUAL]: 'Partner Basic (annual)',
  [PLAN_2020_PARTNER_PREMIUM_ANNUAL]: 'Partner Premium (annual)',
  [PLAN_2020_ENTERPRISE_ANNUAL]: 'Custom (annual)',

  [PLAN_2021_STARTER_MONTHLY]: 'Starter (monthly)',
  [PLAN_2021_LIGHT_MONTHLY]: 'Light (monthly)',
  [PLAN_2021_STANDARD_MONTHLY]: 'Standard (monthly)',
  [PLAN_2021_PREMIUM_MONTHLY]: 'Premium (monthly)',
  [PLAN_2021_PARTNER_BASIC_MONTHLY]: 'Partner Basic (monthly)',
  [PLAN_2020_PARTNER_PREMIUM_MONTHLY]: 'Partner Premium (monthly)',
  [PLAN_2020_ENTERPRISE_MONTHLY]: 'Custom (monthly)',
}

export const ACTIVE_FINAGO_PLANS = {
  [PLAN_FINAGO_LIGHT_MONTHLY]: 'Sopimuskone Kevyt (monthly)',
  [PLAN_FINAGO_BASIC_MONTHLY]: 'Sopimuskone Perus (monthly)',
  [PLAN_FINAGO_PLUS_MONTHLY]: 'Sopimuskone Plus (monthly)',
}

export const ACTIVE_SE_PLANS = {
  [PLAN_SE_2021_STARTER_ANNUAL]: 'Starter (annual)',
  [PLAN_SE_2021_LIGHT_ANNUAL]: 'Light (annual)',
  [PLAN_SE_2021_STANDARD_ANNUAL]: 'Standard (annual)',
  [PLAN_SE_2021_PREMIUM_ANNUAL]: 'Premium (annual)',
  [PLAN_SE_2021_PARTNER_BASIC_ANNUAL]: 'Partner Basic (annual)',
  [PLAN_SE_2021_PARTNER_PREMIUM_ANNUAL]: 'Partner Premium (annual)',
  [PLAN_SE_2021_ENTERPRISE_ANNUAL]: 'Custom (annual)',

  [PLAN_SE_2021_STARTER_MONTHLY]: 'Starter (monthly)',
  [PLAN_SE_2021_LIGHT_MONTHLY]: 'Light (monthly)',
  [PLAN_SE_2021_STANDARD_MONTHLY]: 'Standard (monthly)',
  [PLAN_SE_2021_PREMIUM_MONTHLY]: 'Premium (monthly)',
  [PLAN_SE_2021_PARTNER_BASIC_MONTHLY]: 'Partner Basic (monthly)',
  [PLAN_SE_2021_PARTNER_PREMIUM_MONTHLY]: 'Partner Premium (monthly)',
  [PLAN_SE_2021_ENTERPRISE_MONTHLY]: 'Custom (monthly)',
}

export const LEGACY_FI_PLANS = {
  [PLAN_2020_BASIC_ANNUAL]: 'Basic (annual)',
  [PLAN_2020_PREMIUM_ANNUAL]: 'Premium (annual)',
  [PLAN_2020_PREMIUM_PLUS_ANNUAL]: 'Premium Plus (annual)',
  [PLAN_2020_PARTNER_BASIC_ANNUAL]: 'Partner Basic (annual)',

  [PLAN_2020_BASIC_MONTHLY]: 'Basic (monthly)',
  [PLAN_2020_PREMIUM_MONTHLY]: 'Premium (monthly)',
  [PLAN_2020_PREMIUM_PLUS_MONTHLY]: 'Premium Plus (monthly)',
  [PLAN_2020_PARTNER_BASIC_MONTHLY]: 'Partner Basic (monthly)',

  [PLAN_GENERAL_LIGHT_ANNUAL]: 'Kevyt (annual)',
  [PLAN_GENERAL_BASIC_ANNUAL]: 'Perus (annual)',
  [PLAN_GENERAL_PLUS_ANNUAL]: 'Plus (annual)',
  [PLAN_PARTNER_BASIC_ANNUAL]: 'Partneri Perus (annual)',
  [PLAN_PARTNER_PLUS_ANNUAL]: 'Partneri Plus (annual)',

  [PLAN_GENERAL_LIGHT_MONTHLY]: 'Kevyt (monthly)',
  [PLAN_GENERAL_BASIC_MONTHLY]: 'Perus (monthly)',
  [PLAN_GENERAL_PLUS_MONTHLY]: 'Plus (monthly)',
  [PLAN_PARTNER_BASIC_MONTHLY]: 'Partneri Perus (monthly)',
  [PLAN_PARTNER_PLUS_MONTHLY]: 'Partneri Plus (monthly)',

  [PLAN_LEGACY_COMPANY_S]: 'S-paketti',
}

export const DISCONTINUED_PLANS = [
  PLAN_2020_BASIC_ANNUAL,
  PLAN_2020_PREMIUM_ANNUAL,
  PLAN_2020_PREMIUM_PLUS_ANNUAL,
  PLAN_2020_PARTNER_BASIC_ANNUAL,

  PLAN_2020_BASIC_MONTHLY,
  PLAN_2020_PREMIUM_MONTHLY,
  PLAN_2020_PREMIUM_PLUS_MONTHLY,
  PLAN_2020_PARTNER_BASIC_MONTHLY,

  PLAN_LEGACY_COMPANY,
  PLAN_LEGACY_COMPANY_S,
  PLAN_LEGACY_COMPANY_M,
  PLAN_LEGACY_COMPANY_L,
  PLAN_LEGACY_COMPANY_XL,
]

export const PLAN_TITLES = {
  [PLAN_2021_STARTER_ANNUAL]: 'Starter (annual)',
  [PLAN_2021_LIGHT_ANNUAL]: 'Light (annual)',
  [PLAN_2021_STANDARD_ANNUAL]: 'Standard (annual)',
  [PLAN_2021_PREMIUM_ANNUAL]: 'Premium (annual)',
  [PLAN_2021_PARTNER_BASIC_ANNUAL]: 'Partner Basic (annual)',

  [PLAN_2021_LIGHT_MONTHLY]: 'Light (monthly)',
  [PLAN_2021_STANDARD_MONTHLY]: 'Standard (monthly)',
  [PLAN_2021_PREMIUM_MONTHLY]: 'Premium (monthly)',
  [PLAN_2021_PARTNER_BASIC_MONTHLY]: 'Partner Basic (monthly)',

  [PLAN_SE_2021_STARTER_ANNUAL]: '🇸🇪 Starter (annual)',
  [PLAN_SE_2021_LIGHT_ANNUAL]: '🇸🇪 Light (annual)',
  [PLAN_SE_2021_STANDARD_ANNUAL]: '🇸🇪 Standard (annual)',
  [PLAN_SE_2021_PREMIUM_ANNUAL]: '🇸🇪 Premium (annual)',
  [PLAN_SE_2021_PARTNER_BASIC_ANNUAL]: '🇸🇪 Partner Basic (annual)',
  [PLAN_SE_2021_PARTNER_PREMIUM_ANNUAL]: '🇸🇪 Partner Premium (annual)',
  [PLAN_SE_2021_ENTERPRISE_ANNUAL]: '🇸🇪 Enterprise (annual)',

  [PLAN_SE_2021_LIGHT_MONTHLY]: '🇸🇪 Light (monthly)',
  [PLAN_SE_2021_STANDARD_MONTHLY]: '🇸🇪 Standard (monthly)',
  [PLAN_SE_2021_PREMIUM_MONTHLY]: '🇸🇪 Premium (monthly)',
  [PLAN_SE_2021_PARTNER_BASIC_MONTHLY]: '🇸🇪 Partner Basic (monthly)',
  [PLAN_SE_2021_PARTNER_PREMIUM_MONTHLY]: '🇸🇪 Partner Premium (monthly)',
  [PLAN_SE_2021_ENTERPRISE_MONTHLY]: '🇸🇪 Enterprise (monthly)',

  [PLAN_2020_BASIC_ANNUAL]: 'Basic (annual)',
  [PLAN_2020_PREMIUM_ANNUAL]: 'Premium (annual)',
  [PLAN_2020_PREMIUM_PLUS_ANNUAL]: 'Premium Plus (annual)',
  [PLAN_2020_PARTNER_BASIC_ANNUAL]: 'Partner Basic (annual)',
  [PLAN_2020_PARTNER_PREMIUM_ANNUAL]: 'Partner Premium (annual)',
  [PLAN_2020_ENTERPRISE_ANNUAL]: 'Enterprise (annual)',

  [PLAN_2020_BASIC_MONTHLY]: 'Basic (monthly)',
  [PLAN_2020_PREMIUM_MONTHLY]: 'Premium (monthly)',
  [PLAN_2020_PREMIUM_PLUS_MONTHLY]: 'Premium Plus (monthly)',
  [PLAN_2020_PARTNER_BASIC_MONTHLY]: 'Partner Basic (monthly)',
  [PLAN_2020_PARTNER_PREMIUM_MONTHLY]: 'Partner Premium (monthly)',
  [PLAN_2020_ENTERPRISE_MONTHLY]: 'Enterprise (monthly)',

  [PLAN_GENERAL_LIGHT_ANNUAL]: 'Kevyt (annual)',
  [PLAN_GENERAL_BASIC_ANNUAL]: 'Perus (annual)',
  [PLAN_GENERAL_PLUS_ANNUAL]: 'Plus (annual)',
  [PLAN_PARTNER_BASIC_ANNUAL]: 'Partneri Perus (annual)',
  [PLAN_PARTNER_PLUS_ANNUAL]: 'Partneri Plus (annual)',

  [PLAN_GENERAL_LIGHT_MONTHLY]: 'Kevyt (monthly)',
  [PLAN_GENERAL_BASIC_MONTHLY]: 'Perus (monthly)',
  [PLAN_GENERAL_PLUS_MONTHLY]: 'Plus (monthly)',
  [PLAN_PARTNER_BASIC_MONTHLY]: 'Partneri Perus (monthly)',
  [PLAN_PARTNER_PLUS_MONTHLY]: 'Partneri Plus (monthly)',

  [PLAN_LEGACY_COMPANY]: 'S-paketti',
  [PLAN_LEGACY_COMPANY_S]: 'S-paketti',
  [PLAN_LEGACY_COMPANY_M]: 'M-paketti',
  [PLAN_LEGACY_COMPANY_L]: 'L-paketti',
  [PLAN_LEGACY_COMPANY_XL]: 'XL-paketti',

  [PLAN_FINAGO_LIGHT_MONTHLY]: 'Sopimuskone Kevyt, Finago (monthly)',
  [PLAN_FINAGO_BASIC_MONTHLY]: 'Sopimuskone Perus, Finago (monthly)',
  [PLAN_FINAGO_PLUS_MONTHLY]: 'Sopimuskone Plus, Finago (monthly)',
}

/**
 * Auth methods
 */
export const AUTH_METHOD_PASSWORDLESS = 'passwordless'
export const AUTH_METHOD_PROCOUNTOR = 'procountor'
export const AUTH_METHOD_PASSWORD = 'password'
export const AUTH_METHOD_GOOGLE = 'google'
export const AUTH_METHOD_MICROSOFT = 'microsoft'

/**
 * Payment methods
 */
export const PAYMENT_METHOD_PARTNER = 'partner'
export const PAYMENT_METHOD_CARD = 'card'
export const PAYMENT_METHOD_INVOICE = 'invoice'
export const PAYMENT_METHOD_EINVOICE = 'einvoice'
export const PAYMENT_METHOD_DIRECT_DEBIT = 'direct_debit'
export const PAYMENT_METHOD_PAYPAL = 'paypal'

export const CONTRACT_STAGE_CREATED = 'created'
export const CONTRACT_STAGE_FINALIZED = 'finalized'
export const CONTRACT_STAGE_COMPLETED = 'completed'
export const CONTRACT_STAGE_PREVIEW = 'preview'
export const CONTRACT_STAGE_TEMPLATE_PREVIEW = 'template_preview'
export const CONTRACT_STAGE_CANCELLED = 'cancelled'

/**
 * this should not contain "preview" as
 * it is not intended to be shown on UI's
 */
export const CONTRACT_STAGES = {
  [CONTRACT_STAGE_COMPLETED]: 'common.contract-stages.completed',
  [CONTRACT_STAGE_FINALIZED]: 'common.contract-stages.finalized',
  [CONTRACT_STAGE_CREATED]: 'common.contract-stages.created',
  [CONTRACT_STAGE_PREVIEW]: 'common.contract-stages.preview',
  [CONTRACT_STAGE_CANCELLED]: 'common.contract-stages.cancelled'
}

export const CONTRACT_STAGE_COLORS = {
  [CONTRACT_STAGE_COMPLETED]: 'green-600',
  [CONTRACT_STAGE_CREATED]: 'gray-500',
  [CONTRACT_STAGE_FINALIZED]: 'warning',
  [CONTRACT_STAGE_CANCELLED]: 'orange-400'
}

export const SIGNATURE_STATE_WAITING = 'waiting'
export const SIGNATURE_STATE_COMPLETED = 'completed'
export const SIGNATURE_STATE_CANCELLED = 'cancelled'

export const STAGE_DEVELOPMENT = 'development'
export const STAGE_STAGING = 'staging'
export const STAGE_PRODUCTION = 'production'

export const PERIOD_YEAR = 'year'
export const PERIOD_MONTH = 'month'

export const SUBSCRIPTION_STATUS_ACTIVE = 'active'
export const SUBSCRIPTION_STATUS_NON_RENEWING = 'non_renewing'
export const SUBSCRIPTION_STATUS_CANCELLED = 'cancelled'
export const SUBSCRIPTION_STATUS_TRIAL = 'in_trial'
export const SUBSCRIPTION_STATUS_FUTURE = 'future'
export const SUBSCRIPTION_STATUS_PAUSED = 'paused'

export const ROLE_ADMIN = 'admin'

export const ACCOUNT_PERSONAL = 'personal'
export const ACCOUNT_TYPE_CLIENT = 'client'
export const ACCOUNT_TYPE_COMPANY = 'company'

export const USER_TYPE_CLIENT = 'client'
export const USER_TYPE_INVITE = 'invite'

export const GTM_EVENT_ACTIVATE_SUBSCRIPTION = 'activateSubscription'
export const GTM_EVENT_UPGRADE_SUBSCRIPTION = 'upgradeSubscription'
export const GTM_EVENT_REGISTER_ACCOUNT = 'registerAccount'
export const GTM_EVENT_CONTRACT_COMPLETED = 'contractCompleted'
export const GTM_EVENT_NEW_TRANSACTION = 'newTransaction'

export const ACCOUNT_ITEM_META_TYPE_CUSTOM = 'custom'
export const ACCOUNT_ITEM_META_TYPE_SYSTEM_CATEGORY = 'system-category'
export const ACCOUNT_ITEM_META_TYPE_SYSTEM_CATEGORY_OTHER = 'system-category-other'

export const ACCOUNT_ITEM_META_ID_UNSORTED = 'none'
export const ACCOUNT_ITEM_META_ID_ALL = 'all'

export const URL_EDIT_SIGNATURE = '/settings/profile'
export const ROUTE_EDIT_PASSWORD = '/settings/profile'
export const URL_COMPANY_ADD_USER = '/settings/users'
export const URL_SETTINGS_IDENTIFY = '/settings/identify'

export const HTTP_STATUS_UNPROCESSABLE_ENTITY = 422
export const HTTP_STATUS_CONFLICT = 409

export const FEATURE_IMPORT_TO_ARCHIVE = 'archive-import-pdf'
export const FEATURE_REMINDERS = 'archive-reminders'
export const FEATURE_CUSTOM_ROLES = 'archive-custom-roles'
export const FEATURE_CUSTOM_FOLDERS = 'archive-custom-folders'
export const FEATURE_WORD_EXPORT = 'builder-export-word'
export const FEATURE_USER_TEMPLATES = 'builder-user-templates'
export const FEATURE_FREE_CANVAS_SIGN = 'free_canvas_sign'
export const FEATURE_OVERAGE_CONTRACTS = 'overage-contracts'
export const FEATURE_ENGLISH_TEMPLATES = 'english_templates'
export const FEATURE_SWEDISH_TEMPLATES = 'swedish_templates'
export const FEATURE_FINNISH_TEMPLATES = 'finnish_templates'
export const FEATURE_GERMAN_TEMPLATES = 'german_templates'
export const FEATURE_LAW_SERVICES = 'law-service-chat'
export const FEATURE_INCLUDED_TEMPLATES = 'templates'
export const FEATURE_SIGN_CUSTOM_PDF = 'signing-custom-pdf'
export const FEATURE_CONTACTS = 'contacts'
export const FEATURE_DOCUMENT_COMPLETION_NOTIFICATION = 'document-completion-notification'
export const FEATURE_BUILDER_PREVIEW = 'builder-preview'
export const FEATURE_FORMS_SERVICE = 'forms-service'

export const WORKSPACE_TYPE_COMPANY = 'company'
export const WORKSPACE_TYPE_CONSUMER = 'consumer'

export const LANGUAGE_FI = 'fi'
export const LANGUAGE_EN = 'en'
export const LANGUAGE_SV = 'sv'
export const LANGUAGE_DE = 'de'
export const LANGUAGE_PL = 'pl'

export const UI_LANGUAGES = {
  [LANGUAGE_FI]: 'Suomi',
  [LANGUAGE_EN]: 'English',
  [LANGUAGE_SV]: 'Svenska',
  [LANGUAGE_DE]: 'Deutsch',
}

export const ALL_LANGUAGES = [LANGUAGE_EN, LANGUAGE_FI, LANGUAGE_SV, LANGUAGE_DE, LANGUAGE_PL]

// Supported languages for account items / contracts
export const ACCOUNT_ITEM_LANGUAGES = [
  LANGUAGE_FI,
  LANGUAGE_EN,
  LANGUAGE_SV,
  LANGUAGE_DE
]

export const COUNTRY_FI = 'FI'
export const COUNTRY_SE = 'SE'
export const COUNTRY_UK = 'GB'
export const COUNTRY_DE = 'DE'
export const COUNTRY_PL = 'PL'

export const COUNTRY_FLAGS = {
  [COUNTRY_FI]: '/flags/flag_finland.svg',
  [COUNTRY_SE]: '/flags/flag_sweden.svg',
  [COUNTRY_UK]: '/flags/flag_united_kingdom.svg',
  [COUNTRY_DE]: '/flags/flag_germany.svg',
  [COUNTRY_PL]: '/flags/flag_poland.svg',
}

export const COUNTRY_TO_WEBSITE_LOCALE = {
  [COUNTRY_FI]: 'fi-fi',
  [COUNTRY_SE]: 'sv-se',
  [COUNTRY_UK]: 'en-gb',
  [COUNTRY_DE]: 'de-de',
  [COUNTRY_PL]: 'pl-pl',
}

export const ENABLED_WORKSPACE_COUNTRIES = {
  [COUNTRY_FI]: 'Finland',
  [COUNTRY_SE]: 'Sweden',
  [COUNTRY_UK]: 'United Kingdom',
  [COUNTRY_DE]: 'Germany',
  [COUNTRY_PL]: 'Poland',
}

export const ALL_COUNTRIES = [COUNTRY_FI, COUNTRY_SE, COUNTRY_UK, COUNTRY_DE, COUNTRY_PL]

export const SIGNING_COUNTRIES = [COUNTRY_FI, COUNTRY_SE]

export const MAXIMUM_LEGACY_FILE_SIZE = 5242880
export const MAXIMUM_LEGACY_FILE_SIZE_READABLE = '5MB'
export const MAXIMUM_LEGACY_COMBINED_SIZE_READABLE = '9MB'
export const MAXIMUM_FILE_SIZE = 13631488
export const MAXIMUM_FILE_SIZE_READABLE = '13MB'
export const MAXIMUM_COMBINED_SIZE_READABLE = '15MB'

export const REMINDER_FREQUENCY_ONCE = 'once'

export const REMINDER_STATUS_DUE = 'due'
export const REMINDER_STATUS_FUTURE = 'future'

export const SPECK_TYPE_PERSON = 'person'
export const SPECK_TYPE_COMPANY = 'company'

export const SIGNATURE_TYPE_PERSON = 'person'
export const SIGNATURE_TYPE_COMPANY = 'company'

export const CONTACT_TYPE_PERSON = 'person'
export const CONTACT_TYPE_COMPANY = 'company'

export const SIGNATURE_AUTH_SERVICE_CANVAS = 'canvas'
export const SIGNATURE_AUTH_SERVICE_SIGNICAT = 'signicat'

export const CONTRACT_SIGN_METHOD_MANUAL = 'manual'
export const CONTRACT_SIGN_METHOD_CANVAS = 'canvas'
export const CONTRACT_SIGN_METHOD_STRONG = 'strong'
export const CONTRACT_SIGN_METHOD_DOCUSIGN = 'docusign'

export const NUMBERS = {
  support: {
    written: '+358 50 512 8640',
    tel: 'tel:+358505128640'
  }
}

export const SHAREHOLDER_TYPE_COMPANY = 'company'
export const SHAREHOLDER_TYPE_PERSON = 'person'

export const PARTNER_TYPE_FINAGO = 'finago'
export const PARTNER_TYPE_LOUNEA = 'lounea'

export const SUB_WINDOW_MANAGER_INITIALIZED = 'sub-window-manager-initialized'

export const IDENTIFY_MESSAGE_IDENTIFIED = 'identify-message-identified'
export const IDENTIFY_MESSAGE_FAILED = 'identify-message-failed'

export const PAYMENT_MESSAGE_PURCHASED = 'payment-purchased'
export const PAYMENT_MESSAGE_CONFIG = 'payment-config'

export const PAYMENT_GATEWAY_PAYTRAIL = 'paytrail'
export const PAYMENT_GATEWAY_STRIPE = 'stripe'
export const PAYMENT_GATEWAY_CREDITS = 'credits'
export const PAYMENT_GATEWAY_CONSOLIDATED = 'consolidated'

export const VAT_RATE_FINLAND = 0.24

export const PRODUCT_TYPE_GENERAL = 'general'
export const PRODUCT_TYPE_PARTNER = 'partner'

export const RECORD_TYPE_CONTRACT = 'free-contract'
export const RECORD_TYPE_SIGNATURE = 'signature'
export const RECORD_TYPE_SIGNATURE_SUBSCRIPTION = 'signature-subscription'

export const ADDON_SIGNATURE = 'signature-prepaid'

export const SIGNATURE_LOG_DELIVERY_STATUS_DELIVERED = 'delivered'
export const SIGNATURE_LOG_DELIVERY_STATUS_FAILED = 'failed'
export const SIGNATURE_LOG_DELIVERY_STATUS_PENDING = 'pending'
export const SIGNATURE_LOG_ACTION_INVITE_SMS = 'invite:sms'
export const SIGNATURE_LOG_ACTION_INVITE_EMAIL = 'invite:email'

export const NATIVE_DATE_FORMAT = 'YYYY-MM-DD'
export const LEGACY_DATE_FORMAT = 'DD.MM.YYYY'

export const SUBSCRIBE_MODAL_STAGE_CHOOSE_CUSTOMERSHIP = 1
export const SUBSCRIBE_MODAL_STAGE_SELECT = 2
export const SUBSCRIBE_MODAL_STAGE_CHECKOUT = 3
export const SUBSCRIBE_MODAL_STAGE_THANKS = 4
export const SUBSCRIBE_MODAL_STAGE_FORBIDDEN = 5

export const TEMPLATE_LANGUAGE_TO_PLAN_FEATURE = {
  [LANGUAGE_FI]: FEATURE_FINNISH_TEMPLATES,
  [LANGUAGE_SV]: FEATURE_SWEDISH_TEMPLATES,
  [LANGUAGE_EN]: FEATURE_ENGLISH_TEMPLATES,
  [LANGUAGE_DE]: FEATURE_GERMAN_TEMPLATES,
}

export const TIMEZONE_FI = 'Europe/Helsinki'
export const TIMEZONE_UK = 'Europe/London'
export const TIMEZONE_SE = 'Europe/Stockholm'

export const SIGNUP_PATH = '/signup'
export const LOGIN_PATH = '/login'
export const ROUTES_ALLOWED_WITHOUT_WORKSPACE = [
  '/chat',
  '/chat/invite'
]

// Auth provider statuses
export const AUTH_PROVIDER_STATUS_ERROR = 'error'
export const AUTH_PROVIDER_STATUS_OK = 'ok'
export const AUTH_PROVIDER_STATUS_NO_USER = 'no-user'
export const AUTH_PROVIDER_STATUS_CONFIRMATION_REQUIRED = 'confirmation-required'

// Phone numbers
export const PHONE_ERR_NOT_A_NUMBER = 'NOT_A_NUMBER'
export const PHONE_ERR_INVALID_COUNTRY = 'INVALID_COUNTRY'
export const PHONE_ERR_TOO_SHORT = 'TOO_SHORT'
export const PHONE_ERR_TOO_LONG = 'TOO_LONG'

// subscribe v2
export const SUBSCRIBE_MODAL2_STAGE_PLANS = 'plans'
export const SUBSCRIBE_MODAL2_STAGE_BILLING = 'billing'
export const SUBSCRIBE_MODAL2_STAGE_PAYMENT = 'payment'
export const SUBSCRIBE_MODAL2_STAGE_FINALIZE = 'finalize'
export const SUBSCRIBE_MODAL2_STAGE_THANKS = 'thanks'

export const SUBSCRIBE_MODAL2_STAGES = [
  SUBSCRIBE_MODAL2_STAGE_PLANS,
  SUBSCRIBE_MODAL2_STAGE_BILLING,
  SUBSCRIBE_MODAL2_STAGE_PAYMENT,
  SUBSCRIBE_MODAL2_STAGE_FINALIZE,
  SUBSCRIBE_MODAL2_STAGE_THANKS,
]

export const PREFERENCE_EMAIL_BRANDING = 'email_branding'

export const INVOICE_STATUS_PAYMENT_DUE = 'payment_due'
export const INVOICE_STATUS_NOT_PAID = 'not_paid'
export const INVOICE_STATUS_PAID = 'paid'
export const INVOICE_STATUS_POSTED = 'posted'
export const INVOICE_STATUS_VOIDED = 'voided'
export const INVOICE_STATUS_PENDING = 'pending'

export const TEMPLATE_TYPE_DEFAULT = 'template'
export const TEMPLATE_TYPE_USER = 'userTemplate'
export const TEMPLATE_TYPE_TAILORED = 'tailored'

export const TEMPLATE_ATTR_TYPE_PREFILLED = 'prefilled'
export const TEMPLATE_ATTR_TYPE_CUSTOM = 'custom'

export const FEATURE_ID_SIGNATURE = 'signing'

export const EVENT_CONTRACT_SIGNING_COMPLETED = 'event-contract-signing-complated'

export const SESSION_STORAGE_KEY_SIGN_NAVIGATED_FROM = 'sign-navigated-from'
